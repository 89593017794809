import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from 'src/app/Models/ResponseModel';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  private timeout = 12000;

  private headers = new HttpHeaders({
    'Content-Type': 'application/json;charset=UTF-8',
  });

  constructor(private http: HttpClient) { }

  public request<T>(
    requestUri: string,
    requestData: object,
    type: 'GET' | 'POST' | 'PUT' | 'DELETE'
  ): Promise<ResponseModel<T>> {
    let result: Observable<ResponseModel<T>>;

    let params = [] as any;

    Object.entries(requestData).forEach(([key, value]) =>
      params.push(`${key}=${value}`)
    );

    let paramsString = params.join('&');

    if (paramsString.length != 0) {
      paramsString = '?' + paramsString;
    }

    //we check to see what kind of request we are running and run the request using correct verb
    switch (type) {
      case 'GET':
        //we serialize any data we might need for the get request into a string and pass that in the request uri
        console.log('GET REQUEST', `${requestUri}${paramsString}`);
        result = this.http
          .get<ResponseModel<T>>(`${requestUri}${paramsString}`, {
            headers: this.headers,
            observe: 'response',
          })
          .pipe(
            timeout(this.timeout),
            catchError(
              this.handleError(
                requestUri.split('/')[requestUri.split('/').length - 1],
                requestData as any
              )
            )
          );

        break;

      case 'POST':
        console.log('POST REQUEST', requestUri, requestData);
        result = this.http
          .post<ResponseModel<T>>(requestUri, requestData)
          .pipe(
            timeout(this.timeout),
            catchError(
              this.handleError(
                requestUri.split('/')[requestUri.split('/').length - 1],
                requestData as any
              )
            )
          );

        break;

      case 'PUT':
        console.log('PUT REQUEST', requestUri, requestData);
        result = this.http
          .put<ResponseModel<T>>(requestUri, requestData, requestData)
          .pipe(
            timeout(this.timeout),
            catchError(
              this.handleError(
                requestUri.split('/')[requestUri.split('/').length - 1],
                requestData as any
              )
            )
          );
        break;

      case 'DELETE':
        console.log('DELETE REQUEST', `${requestUri}${paramsString}`);
        result = this.http
          .delete<ResponseModel<T>>(`${requestUri}${paramsString}`, {
            headers: this.headers,
            observe: 'response',
          })
          .pipe(
            timeout(this.timeout),
            catchError(
              this.handleError(
                requestUri.split('/')[requestUri.split('/').length - 1],
                requestData as any
              )
            )
          );

        break;
    }

    return result.toPromise();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(error);
    };
  }
}
