import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailerRqDto, EmailerRsDto } from 'src/app/Models/Email/Email.js';
import { HttpRequestService } from 'src/app/vehicle-finance-calculator/services/http-request.service';
import '../../../assets/smtp.js';

declare let Email: any;

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private controllerUrl = `${environment.baseUrl}/GenericEmail`;

  constructor(private http: HttpRequestService) { }

  public async sendMail(request: EmailerRqDto): Promise<EmailerRsDto> {
    return new Promise<EmailerRsDto>(async (resolve, reject) => {
      let serviceResponse: EmailerRsDto = {
        isSuccessful: false
      };

      await this.http
        .request<EmailerRsDto>(`${this.controllerUrl}/SendPostEmail`, request, 'POST')
        .then((response) => {
          if (response) {
            serviceResponse.isSuccessful = true;
          } else {
            return reject(response);
          }
          resolve(serviceResponse);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
