// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CalculatorConfig } from 'src/app/types';

export const environment: {
        production: boolean;
        baseUrl: string;
        googleAnalyticsToken: string;
        configs: CalculatorConfig;
} = {
        production: false,
        baseUrl: 'https://dev-icon.cloud:9100',
        googleAnalyticsToken: '',
        configs: {
                'd7f48dcc-b71b-4be9-bfc1-954d341bf979': {
                        name: 'Pupkewitz_Main',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#05255C',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                '0df2ba66-1e98-4758-bf68-0b407805f7b2': {
                        name: 'Pupkewitz_Toyota',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#E50000',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'a976e0c8-639d-424c-b72b-d967460b7397': {
                        name: 'Pupkewitz_Lexus',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#292929',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'c0dd3f48-7d60-4bfb-9c86-b12bd89bc4f0': {
                        name: 'Pupkewitz_Honda',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#DC182D',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                '483e5d0b-57f8-4896-adbb-1d9b485d1ae9': {
                        name: 'Pupkewitz_Nissan',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#C3002E',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'c8bf397c-b525-4064-af60-651948ae964f': {
                        name: 'Pupkewitz_Mini',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#000000',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'c224bcb8-3f83-4c2a-b26b-13b9ae0d79cc': {
                        name: 'Pupkewitz_BMW',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#1C69D4',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                '50f4b09c-a4d0-4e29-9fa8-9f6b20b0b946': {
                        name: 'Pupkewitz_GWM',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#D71921',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'dba8969c-2979-4b5c-b33a-707acfb2cf00': {
                        name: 'Pupkewitz_Hino',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#353535',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'b7329717-1530-4de6-9d01-856b048c3ece': {
                        name: 'Pupkewitz_Haval',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#D21627',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                '475aafbf-26d3-4b6c-b72b-94b636d92221': {
                        name: 'Pupkewitz_Datsun',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#CC092F',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'a05ced0f-a9f9-4f96-8c0c-eaf8b9c927aa': {
                        name: 'Pupkewitz_Volkswagen',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#001E50',
                        smtp: {
                                SecureToken: '9b49dcd6-dc4d-4823-9b46-31d4f414e95c',
                                From: 'noreply@pupkewitz.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the Pupkewitz Motor Division website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance:{FinanceTotal}
                          Interest amount:{InterestTotal}
                          Total cost of credit:{TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                '0c1557ef-adb1-4905-9936-738689f3bf16': {
                        name: 'NamAuto_Main',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'N$ ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        colour: '#D51F36',
                        smtp: {
                                SecureToken: '998029e6-9a69-489c-b103-9d783290c484',
                                From: 'noreply@namauto.com',
                                Subject: 'Results of the Finance calculator',
                                Bcc: ['motorsmarketing@pupkewitz.com'],
                                MonthlyInstalmentBody: `Thank you for making use of the online finance calculator on the NamAuto website.
                    <br>
                    <br>
                    <br>
                            For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPaymentRate}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                            Monthly instalments: {MonthlyInstalment}
                    <br>
                            Financing amount: {FinanceTotal}
                    <br>
                            Interest amount: {InterestTotal}
                    <br>
                            Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                    <br>
                            Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Thank you for making use of the online finance calculator on the NamAuto website.
                    <br>
                    <br>
                    <br>
                          For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate} and a balloon payment of {BalloonPayment}, the following will be applicable:
                    <br>
                    <br>
                    <br>
                          Amount I can spend: {PurchasePrice}
                          Amount to finance: {FinanceTotal}
                          Interest amount: {InterestTotal}
                          Total cost of credit: {TotalCostOfCredit}
                    <br>
                    <br>
                          Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                'bbe54b9d-4484-4cf3-8b59-6987a33dff95': {
                        name: 'Suzuki_Axess_Suzuki',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 8,
                        minInterestRate: 8,
                        maxInterestRate: 8,
                        minFinancingAmount: 0,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 60, 72, 84],
                        termIncrement: 12,
                        currency: 'Rs ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: false,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        interestRateLabel: 'Indicative interest rate',
                        colour: '#00A599',
                        smtp: {
                                SecureToken: 'a1d853e5-6d71-4a3f-b1b1-422ce49664aa',
                                From: 'suzuki.sales@axess.mu',
                                Subject: 'Your request - Finance Calculator',
                                Bcc: ['digital@axess.mu', 'suzuki.sales@axess.mu'],
                                MonthlyInstalmentBody: `Dear customer,<br><br>Thank you for making use of the online finance calculator on the Suzuki Axess website.<br><br>For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate}, the following will be applicable:<br><br>Monthly instalments: {MonthlyInstalment}<br>Financing amount: {FinanceTotal}<br>Interest amount: {InterestTotal}<br><br>Total cost of credit: {TotalCostOfCredit}<br><br>Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                                PurchasePriceBody: `Dear customer,<br><br>Thank you for making use of the online finance calculator on the Suzuki Axess website.<br>        <br>For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate}, the following will be applicable:<br>        <br>Amount I can spend: {PurchasePrice}<br>Amount to finance: {FinanceTotal}<br>Interest amount: {InterestTotal}<br>Total cost of credit: {TotalCostOfCredit}<br>         <br>Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.`,
                        },
                },
                /* Team Vaal */
                '8bc4cdd8-c01a-424f-b9b5-82dfcfeff5ea': {
                        name: 'Team_Vaal_Group',
                        defaultBalloonPercentage: 30,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 14,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#000000',
                        smtp: {
                                SecureToken: '3b0675f1-f50d-4bd8-a80c-8f9441ef9411',
                                From: 'smd@teamvaal.co.za',
                                Subject: 'Finance Enquiry with Team Vaal',
                                Bcc: ['smd@teamvaal.co.za'],
                                MonthlyInstalmentBody: `Dear valued customer,<br><br>Thank you for the finance enquiry. A member of our team will contact you soonest.<br><br>Regards<br>Team Vaal`,
                                PurchasePriceBody: `Dear valued customer,<br><br>Thank you for the finance enquiry. A member of our team will contact you soonest.<br><br>Regards<br>Team Vaal`,
                        },
                },
                'b0309ecd-77f4-435a-acfc-4806a465e29d': {
                        name: 'Team_Vaal_Volkswagen',
                        defaultBalloonPercentage: 30,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 10,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#0000ee',
                        smtp: {
                                SecureToken: '3b0675f1-f50d-4bd8-a80c-8f9441ef9411',
                                From: 'smd@teamvaal.co.za',
                                Subject: 'Finance Enquiry with Team Vaal',
                                Bcc: ['smd@teamvaal.co.za'],
                                MonthlyInstalmentBody: `Dear valued customer,<br><br>Thank you for the finance enquiry. A member of our team will contact you soonest.<br><br>Regards<br>Team Vaal`,
                                PurchasePriceBody: `Dear valued customer,<br><br>Thank you for the finance enquiry. A member of our team will contact you soonest.<br><br>Regards<br>Team Vaal`,
                        },
                },
                'd9c48a60-07fe-415d-8aee-716a0a9a2a93': {
                        name: 'Team_Vaal_Mazda',
                        defaultBalloonPercentage: 30,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 10,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#101010',
                        smtp: {
                                SecureToken: '3b0675f1-f50d-4bd8-a80c-8f9441ef9411',
                                From: 'smd@teamvaal.co.za',
                                Subject: 'Finance Enquiry with Team Vaal',
                                Bcc: ['smd@teamvaal.co.za'],
                                MonthlyInstalmentBody: `Dear valued customer,<br><br>Thank you for the finance enquiry. A member of our team will contact you soonest.<br><br>Regards<br>Team Vaal`,
                                PurchasePriceBody: `Dear valued customer,<br><br>Thank you for the finance enquiry. A member of our team will contact you soonest.<br><br>Regards<br>Team Vaal`,
                        },
                },
                '00000000-0000-0000-0000-000000000000': {
                        name: 'TEST_Team_Vaal_Mazda_TEST',
                        defaultBalloonPercentage: 30,
                        minBalloonRate: 0,
                        maxBalloonRate: 30,
                        defaultInterestRate: 10,
                        minInterestRate: 5,
                        maxInterestRate: 20,
                        minFinancingAmount: 30000,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72,
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#101010',
                        smtp: {
                                SecureToken: 'a1d853e5-6d71-4a3f-b1b1-422ce49664aa',
                                From: 'suzuki.sales@axess.mu',
                                Subject: 'Your request - Finance Calculator',
                                Bcc: [],
                                MonthlyInstalmentBody: `TEST<br>Dear customer,<br><br>Thank you for making use of the online finance calculator on the Suzuki Axess website.<br><br>For a vehicle costing a total of {PurchasePrice}, and placing a deposit of {Deposit} with an interest rate of {InterestRate}, the following will be applicable:<br><br>Monthly instalments: {MonthlyInstalment}<br>Financing amount: {FinanceTotal}<br>Interest amount: {InterestTotal}<br><br>Total cost of credit: {TotalCostOfCredit}<br><br>Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.<br>TEST`,
                                PurchasePriceBody: `TEST<br>Dear customer,<br><br>Thank you for making use of the online finance calculator on the Suzuki Axess website.<br>        <br>For a monthly instalment of {MonthlyInstalment}, and placing a deposit of {Deposit} with an interest rate of {InterestRate}, the following will be applicable:<br>        <br>Amount I can spend: {PurchasePrice}<br>Amount to finance: {FinanceTotal}<br>Interest amount: {InterestTotal}<br>Total cost of credit: {TotalCostOfCredit}<br>         <br>Kindly note that all calculations made on this calculator are to be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. For further information, please contact the branch of your choice and discuss final figures with the available in-house F&I.<br>TEST`,
                        },
                },
                '0f91963e-2c1b-4dfb-9f24-04810971a26b': {
                        name: 'Thekwini_Group_Hino',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 35,
                        defaultInterestRate: 12,
                        minInterestRate: 10,
                        maxInterestRate: 20,
                        minFinancingAmount: 0,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#E50000',
                        smtp: {
                                SecureToken: '0f91963e-2c1b-4dfb-9f24-04810971a26b',
                                From: 'hello@thekwinimotorgroup.co.za',
                                Subject: 'Thank You for Applying for Finance with TMG!',
                                Bcc: ['cheron@thekwinimotorgroup.co.za'],
                                MonthlyInstalmentBody: `Dear [Applicant],<br><br>Thank you for applying for finance! Should you require a friendly sales representative to contact you, please respond with the following information:<br><br>Name:<br>Surname:<br>Vehicle Interested In:<br>Contact Number:<br>Email Address:<br>Finance: Yes/No<br><br>Best regards,<br>[Your Name]`,
                        },
                },
                '7e01d7bf-7000-4ddf-a87e-2a2d2fb3a068': {
                        name: 'Thekwini_Group_Toyota',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 35,
                        defaultInterestRate: 12,
                        minInterestRate: 10,
                        maxInterestRate: 20,
                        minFinancingAmount: 0,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#E50000',
                        smtp: {
                                SecureToken: '7e01d7bf-7000-4ddf-a87e-2a2d2fb3a068',
                                From: 'hello@thekwinimotorgroup.co.za',
                                Subject: 'Thank You for Applying for Finance with TMG!',
                                Bcc: ['cheron@thekwinimotorgroup.co.za'],
                                MonthlyInstalmentBody: `Dear [Applicant],<br><br>Thank you for applying for finance! Should you require a friendly sales representative to contact you, please respond with the following information:<br><br>Name:<br>Surname:<br>Vehicle Interested In:<br>Contact Number:<br>Email Address:<br>Finance: Yes/No<br><br>Best regards,<br>[Your Name]`,
                        },
                },'7a1c2c59-e041-46ab-bd3f-e931989a3736': {
                        name: 'Allen_Joss_Mazda',
                        defaultBalloonPercentage: 0,
                        minBalloonRate: 0,
                        maxBalloonRate: 35,
                        defaultInterestRate: 12,
                        minInterestRate: 8,
                        maxInterestRate: 20,
                        minFinancingAmount: 0,
                        defaultPaymentTerm: 48,
                        paymentTerms: [12, 24, 36, 48, 54, 60, 72],
                        termIncrement: 12,
                        currency: 'R ',
                        maxFinancingAmount: 10000000,
                        minMonthlyInstalment: 303.36,
                        maxMonthlyInstalment: 926345.06,
                        minTerm: 0,
                        maxTerm: 0,
                        minDeposit: 0,
                        maxDeposit: 1,
                        defaultDeposit: 0,
                        minBalloonAmount: 0,
                        maxBalloonAmount: 1,
                        showMontlyInstalment: true,
                        showPurchasePrice: true,
                        showBalloonPayment: true,
                        balloonPaymentLimits: {
                                minTerm: 0,
                                maxTerm: 72
                        },
                        interestRateLabel: 'Interest rate',
                        colour: '#21130d',
                },
        },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
