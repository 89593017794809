<div class="page-container">
    <div class="calculator">
        <div class="calculator-title">
            Vehicle Finance Calculator
        </div>
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Monthly instalments" *ngIf="staticConfig.showMontlyInstalment">
                <div class="tab-title">Calculate how much your monthly car instalment will be.</div>
                <div class="grid">
                    <!-- <form [formGroup]="MonthlyInstalmentForm"> -->
                    <div class="inputs">
                            <div class="calculator-field">
                                <mat-label>Purchase price</mat-label>
                                <mat-form-field style="display: block;">
                                    <span matPrefix>{{staticConfig.currency}} &nbsp;</span>
                                    <input matInput type="text" [(ngModel)]="inputs.purchasePriceDisplay" (input)="purchasePriceInput($event);" (focus)="unformatText($event, 'purchasePrice', 'MONTHLY')" (blur)="basicInputBlur($event, 'purchasePrice', 'MONTHLY')">
                                </mat-form-field>
                                <mat-error *ngIf="MonthlyInstalmentForm.get('purchasePrice')?.errors">
                                    <span *ngIf="inputs.purchasePrice < staticConfig.minFinancingAmount">Minimum purchase price is {{staticConfig.currency}} {{staticConfig.minFinancingAmount | number: '1.2-2'}}</span>
                                    <span *ngIf="inputs.purchasePrice > staticConfig.maxFinancingAmount">Maximum purchase price is {{staticConfig.currency}} {{staticConfig.maxFinancingAmount | number: '1.2-2'}}</span>
                                </mat-error>
                            </div>
                            <div class="calculator-field">
                                <div class="top">
                                    <mat-label>Deposit</mat-label>
                                    <mat-form-field>
                                        <span matPrefix>{{staticConfig.currency}} &nbsp;</span>
                                        <input [(ngModel)]="inputs.depositMonthlyInstalmentDisplay" [disabled]="inputs.purchasePrice <= staticConfig.minFinancingAmount" matInput type="text" (focus)="unformatText($event, 'deposit' ,'MONTHLY')" (input)="basicInput($event, 'deposit' ,'MONTHLY');" (blur)="basicInputBlur($event, 'deposit', 'MONTHLY')">
                                    </mat-form-field>
                                </div>
                                <mat-slider min="{{staticConfig.minDeposit}}" max="{{staticConfig.maxDeposit}}" step="1000" [disabled]="inputs.purchasePrice <= staticConfig.minFinancingAmount" [(ngModel)]="inputs.depositMonthlyInstalment" (input)="inputs.depositMonthlyInstalment = $event.value || 0; updateSliderDisplay('depositMonthly'); calculateMonthlyInstalment()"></mat-slider>
                            </div>
                            <div class="calculator-field" *ngIf="staticConfig.showBalloonPayment">
                                <div class="top">
                                    <mat-label>Balloon payment</mat-label>
                                    <mat-form-field>
                                        <input matInput type="number" [min]="staticConfig.minBalloonRate" [max]="staticConfig.maxBalloonRate" [(ngModel)]="inputs.balloonPaymentPercentage" (input)="basicInput($event, 'balloonPaymentPercentage' ,'MONTHLY');" (blur)="basicInputBlur($event, 'balloonPaymentPercentage', 'MONTHLY')" [disabled]="!!staticConfig.disableBalloonPayment">
                                        <span matSuffix>%</span>
                                    </mat-form-field>
                                </div>
                                <mat-slider min="{{staticConfig.minBalloonRate}}" max="{{staticConfig.maxBalloonRate}}" step="1" [(ngModel)]="inputs.balloonPaymentPercentage" [disabled]="!!staticConfig.disableBalloonPayment" (input)="inputs.balloonPaymentPercentage = $event.value || 0; calculateMonthlyInstalment()"></mat-slider>
                                <mat-error *ngIf="!!staticConfig.disableBalloonPayment == true && staticConfig.balloonPaymentLimits?.maxTerm != null">
                                    <span class="disable-note" *ngIf="staticConfig.balloonPaymentLimits?.minTerm != null && inputs.paymentTerm < staticConfig.balloonPaymentLimits!.minTerm">Not available below {{staticConfig.balloonPaymentLimits?.minTerm}} months</span>
                                    <span class="disable-note" *ngIf="staticConfig.balloonPaymentLimits?.maxTerm != null && inputs.paymentTerm > staticConfig.balloonPaymentLimits!.maxTerm">Not available over {{staticConfig.balloonPaymentLimits?.maxTerm}} months</span>
                                </mat-error>
                            </div>
                            <div class="calculator-field">
                                <div class="top">
                                    <mat-label>{{staticConfig.interestRateLabel || "Interest rate"}}</mat-label>
                                    <mat-form-field>
                                        <input matInput type="number" [min]="staticConfig.minInterestRate" [max]="staticConfig.maxInterestRate" [disabled]="staticConfig.minInterestRate == staticConfig.maxInterestRate" [(ngModel)]="inputs.interestRate" (input)="basicInput($event, 'interestRate' ,'MONTHLY');" (blur)="basicInputBlur($event, 'interestRate', 'MONTHLY')">
                                        <span matSuffix>%</span>
                                    </mat-form-field>
                                </div>
                                <mat-slider min="{{staticConfig.minInterestRate}}" max="{{staticConfig.maxInterestRate}}" step="1" [(ngModel)]="inputs.interestRate" [disabled]="staticConfig.minInterestRate == staticConfig.maxInterestRate" (input)="inputs.interestRate = $event.value || 0; calculateMonthlyInstalment()"></mat-slider>
                            </div>
                            <div class="calculator-field">
                                <div class="top">
                                    <mat-label>Payment term</mat-label>
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="inputs.paymentTerm" (selectionChange)="inputs.paymentTerm = $event.value || 0; checkBalloonPaymentLimits(); calculateMonthlyInstalment()">
                                            <mat-option *ngFor="let term of staticConfig.paymentTerms" [value]="term">{{term}}</mat-option>
                                        </mat-select>
                                        <span matSuffix>&nbsp; months</span>
                                    </mat-form-field>
                                </div>
                                <mat-slider min="{{staticConfig.minTerm}}" max="{{staticConfig.maxTerm}}" [step]="staticConfig.termIncrement" [(ngModel)]="inputs.paymentTerm"  (input)="inputs.paymentTerm = $event.value || 0; checkBalloonPaymentLimits(); calculateMonthlyInstalment()"></mat-slider>
                            </div>
                        </div>
                    <!-- </form> -->
                    <div class="outputs">
                        <mat-card class="card">
                            <mat-card-header>
                                <mat-card-title>Your estimated monthly instalment will be:</mat-card-title>
                            </mat-card-header>
                            <!-- <mat-progress-bar mode="determinate" value="{{outputsMonthlyInstalment.inverseInterestRatio}}"></mat-progress-bar> -->
                            <mat-card-content class="card-content">
                                <div class="total-amount">{{staticConfig.currency}} {{outputsMonthlyInstalment.monthlyInstalment | number: '1.2-2'}}</div>
                                <div class="finance-label">
                                    <span class="inline">Amount to finance:</span>
                                    <span class="inline finance-value">{{staticConfig.currency}} {{outputsMonthlyInstalment.financeTotal | number: '1.2-2'}}</span>
                                </div>
                                <div class="finance-label">
                                    <span class="inline">Interest amount:</span>
                                    <span class="inline finance-value">{{staticConfig.currency}} {{outputsMonthlyInstalment.interestTotal | number: '1.2-2'}}</span>
                                </div>
                                <div class="finance-label">
                                    <span class="inline">Total cost of credit:</span>
                                    <span class="inline finance-value">{{staticConfig.currency}} {{outputsMonthlyInstalment.totalCost | number: '1.2-2'}}</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <form [formGroup]="EmailForm" (ngSubmit)="sendEmail('MONTHLY')" *ngIf="staticConfig.canEmail">
                            <mat-form-field class="email-input" appearance="outline">
                                <mat-label>Email address</mat-label>
                                <input matInput type="email" formControlName="email" required [(ngModel)]="inputs.emailAddress">
                                <mat-icon class="send" matSuffix *ngIf="!EmailForm.get('email')?.errors && !isSendInProgress" (click)="sendEmail('MONTHLY')">send</mat-icon>
                                <mat-spinner matSuffix diameter="20" color="#00" *ngIf="isSendInProgress"></mat-spinner>
                                <mat-error *ngIf="EmailForm.get('email')?.errors">Invalid email</mat-error>
                            </mat-form-field>
                        </form>
                        <button mat-stroked-button class="form-button" (click)="resetToDefault()">Reset</button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Purchase price" *ngIf="staticConfig.showPurchasePrice">
                <div class="tab-title">Calculate the amount you can buy for.</div>
                <div class="grid">
                    <div class="inputs">
                        <div class="calculator-field">
                            <mat-label>Monthly instalment</mat-label>
                            <mat-form-field style="display: block;">
                                <span matPrefix>{{staticConfig.currency}} &nbsp;</span>
                                <input matInput type="text" [(ngModel)]="inputs.monthlyInstalmentDisplay" (focus)="unformatText($event, 'monthlyInstalment' ,'PURCHASEPRICE')" (input)="monthlyInstalmentInput($event);" (input)="basicInput($event, 'monthlyInstalment' ,'PURCHASEPRICE');" (blur)="basicInputBlur($event, 'monthlyInstalment', 'PURCHASEPRICE')">
                            </mat-form-field>
                        </div>
                        <div class="calculator-field">
                            <div>
                                <mat-label>Deposit</mat-label>
                                <mat-form-field style="display: block;">
                                    <span matPrefix>{{staticConfig.currency}} &nbsp;</span>
                                    <input matInput type="text" [(ngModel)]="inputs.depositPurchasePriceDisplay" [disabled]="outputsPurchasePrice.purchasePrice <= staticConfig.minFinancingAmount && inputs.depositPurchasePrice == 0" (focus)="unformatText($event, 'deposit' ,'PURCHASEPRICE')" (input)="basicInput($event, 'deposit' ,'PURCHASEPRICE');" (blur)="basicInputBlur($event, 'deposit', 'PURCHASEPRICE')">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="calculator-field" *ngIf="staticConfig.showBalloonPayment">
                            <div class="top">
                                <mat-label>Balloon payment</mat-label>
                                <mat-form-field>
                                    <span matPrefix>{{staticConfig.currency}} &nbsp;</span>
                                    <input matInput type="text" [disabled]="outputsPurchasePrice.purchasePrice <= 1000" [(ngModel)]="inputs.balloonPaymentAmountDisplay" (focus)="unformatText($event, 'balloonAmount' ,'PURCHASEPRICE')" (input)="basicInput($event, 'balloonPaymentAmount' ,'PURCHASEPRICE');" (blur)="basicInputBlur($event, 'balloonPaymentAmount', 'PURCHASEPRICE')" [disabled]="!!staticConfig.disableBalloonPayment">
                                </mat-form-field>
                            </div>
                            <mat-slider min="{{staticConfig.minBalloonAmount}}" max="{{staticConfig.maxBalloonAmount}}" step="1000" [disabled]="outputsPurchasePrice.purchasePrice <= 1000 || !!staticConfig.disableBalloonPayment" [(ngModel)]="inputs.balloonPaymentAmount"  (input)="inputs.balloonPaymentAmount = $event.value || 0; updateSliderDisplay('balloonAmount'); calculatePurchasePrice()"></mat-slider>
                            <mat-error *ngIf="!!staticConfig.disableBalloonPayment == true && staticConfig.balloonPaymentLimits?.maxTerm != null">
                                <span class="disable-note" *ngIf="staticConfig.balloonPaymentLimits?.minTerm != null && inputs.paymentTerm < staticConfig.balloonPaymentLimits!.minTerm">Not available below {{staticConfig.balloonPaymentLimits?.minTerm}} months</span>
                                <span class="disable-note" *ngIf="staticConfig.balloonPaymentLimits?.maxTerm != null && inputs.paymentTerm > staticConfig.balloonPaymentLimits!.maxTerm">Not available over {{staticConfig.balloonPaymentLimits?.maxTerm}} months</span>
                            </mat-error>
                        </div>
                        <div class="calculator-field">
                            <div class="top">
                                <mat-label>{{staticConfig.interestRateLabel || "Interest rate"}}</mat-label>
                                <mat-form-field>
                                    <input matInput type="number" [min]="staticConfig.minInterestRate" [max]="staticConfig.maxInterestRate" [disabled]="staticConfig.minInterestRate == staticConfig.maxInterestRate" [(ngModel)]="inputs.interestRate" (input)="basicInput($event, 'interestRate' ,'PURCHASEPRICE');" (blur)="basicInputBlur($event, 'interestRate', 'PURCHASEPRICE')">
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                            <mat-slider min="{{staticConfig.minInterestRate}}" max="{{staticConfig.maxInterestRate}}" step="1" [(ngModel)]="inputs.interestRate" [disabled]="staticConfig.minInterestRate == staticConfig.maxInterestRate" (input)="inputs.interestRate = $event.value || 0; calculatePurchasePrice()"></mat-slider>
                        </div>
                        <div class="calculator-field">
                            <div class="top">
                                <mat-label>Payment term</mat-label>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="inputs.paymentTerm" (selectionChange)="inputs.paymentTerm = $event.value || 0; checkBalloonPaymentLimits(); calculatePurchasePrice()">
                                        <mat-option *ngFor="let term of staticConfig.paymentTerms" [value]="term">{{term}}</mat-option>
                                    </mat-select>
                                    <span matSuffix>&nbsp; months</span>
                                </mat-form-field>
                            </div>
                            <mat-slider min="{{staticConfig.minTerm}}" max="{{staticConfig.maxTerm}}" [step]="staticConfig.termIncrement" [(ngModel)]="inputs.paymentTerm"  (input)="inputs.paymentTerm = $event.value || 0; checkBalloonPaymentLimits(); calculatePurchasePrice()"></mat-slider>
                        </div>
                    </div>
                    <div class="outputs">
                        <mat-card class="card">
                            <mat-card-header>
                                <mat-card-title>Amount I can spend:</mat-card-title>
                            </mat-card-header>
                            <!-- <mat-progress-bar mode="determinate" value="{{outputsPurchasePrice.inverseInterestRatio}}"></mat-progress-bar> -->
                            <mat-card-content class="card-content">
                                <div class="total-amount">{{staticConfig.currency}} {{outputsPurchasePrice.purchasePrice | number: '1.2-2'}}</div>
                                <div class="finance-label">
                                    <span class="inline">Amount to finance:</span>
                                    <span class="inline finance-value">{{staticConfig.currency}} {{outputsPurchasePrice.financeTotal | number: '1.2-2'}}</span>
                                </div>
                                <div class="finance-label">
                                    <span class="inline">Interest amount:</span>
                                    <span class="inline finance-value">{{staticConfig.currency}} {{outputsPurchasePrice.interestTotal | number: '1.2-2'}}</span>
                                </div>
                                <div class="finance-label">
                                    <span class="inline">Total cost of credit:</span>
                                    <span class="inline finance-value">{{staticConfig.currency}} {{outputsPurchasePrice.totalCost | number: '1.2-2'}}</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <form [formGroup]="EmailForm" (ngSubmit)="sendEmail('PURCHASEPRICE')" *ngIf="staticConfig.canEmail">
                            <mat-form-field class="email-input" appearance="outline">
                                <mat-label>Email address</mat-label>
                                <input matInput type="email" formControlName="email" required [(ngModel)]="inputs.emailAddress">
                                <mat-icon class="send" matSuffix *ngIf="!EmailForm.get('email')?.errors && !isSendInProgress" (click)="sendEmail('PURCHASEPRICE')">send</mat-icon>
                                <mat-spinner matSuffix diameter="20" color="#00" *ngIf="isSendInProgress"></mat-spinner>
                                <mat-error *ngIf="EmailForm.get('email')?.errors">Invalid email</mat-error>
                            </mat-form-field>
                        </form>
                        <button mat-stroked-button class="form-button" (click)="resetToDefault()">Reset</button>
                    </div>
                </div></mat-tab>
        </mat-tab-group>
    </div>
</div>