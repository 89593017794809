import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VehicleFinanceCalculatorComponent } from './vehicle-finance-calculator/vehicle-finance-calculator.component';

const routes: Routes = [
  { path: 'calculator/:guid', component: VehicleFinanceCalculatorComponent },
  { path: '', redirectTo: '/calculator', pathMatch: 'full' },
  { path: 'calculator', component: VehicleFinanceCalculatorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
