import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from './services/email.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EmailerRqDto } from '../Models/Email/Email';

@Component({
  selector: 'app-vehicle-finance-calculator',
  templateUrl: './vehicle-finance-calculator.component.html',
  styleUrls: ['./vehicle-finance-calculator.component.scss'],
})
export class VehicleFinanceCalculatorComponent implements OnInit {
  public EmailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  private guid: string = '';
  private configName: string = '';

  public staticConfig: {
    currency: string;
    minFinancingAmount: number;
    maxFinancingAmount: number;
    minMonthlyInstalment: number;
    maxMonthlyInstalment: number;
    paymentTerms: Array<number>;
    minTerm: number;
    maxTerm: number;
    termIncrement: number;
    minInterestRate: number;
    maxInterestRate: number;
    minDeposit: number;
    maxDeposit: number;
    minBalloonRate: number;
    maxBalloonRate: number;
    minBalloonAmount: number;
    maxBalloonAmount: number;
    showMontlyInstalment: boolean;
    showPurchasePrice: boolean;
    showBalloonPayment: boolean;
    disableBalloonPayment?: boolean;
    balloonPaymentLimits?: {
      minTerm: number;
      maxTerm: number;
    } | null;
    interestRateLabel?: string;
    canEmail?: boolean;
  } = {
    currency: '',
    minFinancingAmount: 0,
    maxFinancingAmount: 0,
    minMonthlyInstalment: 0,
    maxMonthlyInstalment: 0,
    paymentTerms: [],
    minTerm: 0,
    maxTerm: 0,
    termIncrement: 0,
    minInterestRate: 0,
    maxInterestRate: 1,
    minDeposit: 0,
    maxDeposit: 1,
    minBalloonRate: 0,
    maxBalloonRate: 1,
    minBalloonAmount: 0,
    maxBalloonAmount: 1,
    showMontlyInstalment: false,
    showPurchasePrice: false,
    showBalloonPayment: true,
    disableBalloonPayment: true,
    balloonPaymentLimits: null,
    canEmail: false,
  };

  public inputs: {
    purchasePrice: number;
    purchasePriceDisplay: string;
    depositMonthlyInstalment: number;
    depositMonthlyInstalmentDisplay: string;
    depositPurchasePrice: number;
    depositPurchasePriceDisplay: string;
    balloonPaymentPercentage: number;
    balloonPaymentAmount: number;
    balloonPaymentAmountDisplay: string;
    interestRate: number;
    paymentTerm: number;
    monthlyInstalmentDisplay: string;
    monthlyInstalment: number;
    emailAddress: string;
  } = {
      purchasePrice: 0,
      purchasePriceDisplay: '0',
      depositMonthlyInstalment: 0,
      depositMonthlyInstalmentDisplay: '0',
      depositPurchasePrice: 0,
      depositPurchasePriceDisplay: '0',
      balloonPaymentPercentage: 0,
      balloonPaymentAmount: 0,
      balloonPaymentAmountDisplay: '0',
      interestRate: 8,
      paymentTerm: 48,
      monthlyInstalment: 0,
      monthlyInstalmentDisplay: '0',
      emailAddress: '',
    };

  public outputsMonthlyInstalment: {
    financeTotal: number;
    interestTotal: number;
    balloonTotal: number;
    totalCost: number;
    monthlyInstalment: number;
    purchasePrice: number;
    inverseInterestRatio: number;
  } = {
      financeTotal: 0,
      interestTotal: 0,
      balloonTotal: 0,
      totalCost: 0,
      monthlyInstalment: 0,
      purchasePrice: 0,
      inverseInterestRatio: 100,
    };

  public outputsPurchasePrice: {
    financeTotal: number;
    interestTotal: number;
    balloonTotal: number;
    totalCost: number;
    monthlyInstalment: number;
    purchasePrice: number;
    inverseInterestRatio: number;
  } = {
      financeTotal: 0,
      interestTotal: 0,
      balloonTotal: 0,
      totalCost: 0,
      monthlyInstalment: 0,
      purchasePrice: 0,
      inverseInterestRatio: 100,
    };

  public isSendInProgress = false;

  public MonthlyInstalmentForm = new FormGroup({
    purchasePrice: new FormControl(this.inputs.purchasePrice, [
      Validators.min(this.staticConfig.minFinancingAmount),
      Validators.max(this.staticConfig.maxFinancingAmount),
    ]),
  });

  constructor(
    private route: ActivatedRoute,
    private emailService: EmailService,
    private snack: MatSnackBar,
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let guid = params.get('guid');

      if (guid) {
        this.guid = guid;
        this.loadConfig();
      } else {
        this.$gaService.pageView('', 'No config');
      }
    });
  }

  private loadConfig(): void {
    let config = environment.configs[this.guid];

    this.configName = config.name;
    this.$gaService.pageView('', this.configName);

    this.staticConfig = config;

    this.staticConfig.canEmail = !!config.smtp;

    document.documentElement.style.setProperty(
      '--active-colour',
      `${config.colour}`
    );

    this.inputs.interestRate = config.defaultInterestRate;
    this.inputs.paymentTerm = config.defaultPaymentTerm;
    this.inputs.depositMonthlyInstalment = config.defaultDeposit;
    this.inputs.balloonPaymentPercentage = config.defaultBalloonPercentage;

    this.staticConfig.minTerm = Math.min(...this.staticConfig.paymentTerms);
    this.staticConfig.maxTerm = Math.max(...this.staticConfig.paymentTerms);

    this.MonthlyInstalmentForm = new FormGroup({
      purchasePrice: new FormControl(this.inputs.purchasePrice, [
        Validators.min(this.staticConfig.minFinancingAmount),
        Validators.max(this.staticConfig.maxFinancingAmount),
      ]),
    });

    this.checkBalloonPaymentLimits();
  }

  /**
   * Function to calculate the outputs
   */
  public calculateMonthlyInstalment(): void {
    /* Ensure the form is valid before calculating */
    if (!this.isMonthlyInstalmentFormValid()) {
      this.resetOutput();
      return;
    }

    let interestRatePercPM = this.inputs.interestRate / 100;
    interestRatePercPM /= 12;

    let cumInterest = 1;

    for (
      let repayCount = 0;
      repayCount < this.inputs.paymentTerm;
      repayCount++
    ) {
      cumInterest *= 1 + interestRatePercPM;
    }

    let financingAmount =
      this.inputs.purchasePrice /* + this.INITIATION_FEE */ -
      this.inputs.depositMonthlyInstalment;

    let balloonAmount =
      (financingAmount * this.inputs.balloonPaymentPercentage) / 100;

    /* If the selected payment terms is outside of the balloon payment limits, do not take the balloon payment into account */
    if (
      this.staticConfig.balloonPaymentLimits != null &&
      (this.inputs.paymentTerm >
        this.staticConfig.balloonPaymentLimits.maxTerm ||
        this.inputs.paymentTerm <
          this.staticConfig.balloonPaymentLimits.minTerm)
    ) {
      balloonAmount = 0;
    }

    let monthlyValue =
      ((financingAmount - balloonAmount / cumInterest) *
        cumInterest *
        interestRatePercPM) /
      (cumInterest - 1);

    // let montlyValue = r/*  + this.ADMIN_FEE */;

    let totalAmount = monthlyValue * this.inputs.paymentTerm + balloonAmount;

    let interestAmount =
      monthlyValue * this.inputs.paymentTerm + balloonAmount - financingAmount;

    this.outputsMonthlyInstalment = {
      monthlyInstalment: this.parseTwoDecimal(monthlyValue),
      financeTotal: this.parseTwoDecimal(
        this.inputs.purchasePrice - this.inputs.depositMonthlyInstalment
      ),
      interestTotal: this.parseTwoDecimal(interestAmount),
      balloonTotal: this.parseTwoDecimal(balloonAmount),
      totalCost: this.parseTwoDecimal(totalAmount),
      inverseInterestRatio: this.parseTwoDecimal(
        ((totalAmount - interestAmount) / totalAmount) * 100
      ),
      purchasePrice: 0,
    };
  }

  /**
   * Function to handle the input from the purchase price field
   * @param event
   */
  public purchasePriceInput(event: any): void {
    this.inputs.purchasePrice = event.target.value || 0;

    if (
      this.inputs.purchasePrice >= this.staticConfig.minFinancingAmount &&
      this.inputs.purchasePrice <= this.staticConfig.maxFinancingAmount
    ) {
      this.staticConfig.maxDeposit = this.parseTwoDecimal(
        this.inputs.purchasePrice - this.staticConfig.minFinancingAmount
      );

      if (this.inputs.depositMonthlyInstalment > this.staticConfig.maxDeposit) {
        if (this.staticConfig.maxDeposit == 0) {
          this.staticConfig.maxDeposit = 1;
        }

        this.inputs.depositMonthlyInstalment =
          this.staticConfig.maxDeposit == 1 ? 0 : this.staticConfig.maxDeposit;
      }

      this.calculateMonthlyInstalment();
    } else {
      this.staticConfig.maxDeposit = 1;
      this.resetOutput();
    }
  }

  /**
   * Function to handle the input from the monthly instalment input
   * @param event
   */
  public monthlyInstalmentInput(event: any): void {
    this.inputs.monthlyInstalment = event.target.value || 0;

    if (
      this.inputs.monthlyInstalment >= this.staticConfig.minMonthlyInstalment &&
      this.inputs.monthlyInstalment <= this.staticConfig.maxMonthlyInstalment
    ) {
      let tempMax = this.parseTwoDecimal(
        this.outputsPurchasePrice.purchasePrice -
        this.staticConfig.minFinancingAmount
      );

      this.staticConfig.maxDeposit = tempMax >= 0 ? tempMax : 0;

      /* REMOVED FOR NOW */
      // if (this.inputs.depositPurchasePrice > this.staticConfig.maxDeposit) {
      //   if (this.staticConfig.maxDeposit == 0) {
      //     this.staticConfig.maxDeposit = 1;
      //   }

      //   this.inputs.depositPurchasePrice =
      //     this.staticConfig.maxDeposit == 1 ? 0 : this.staticConfig.maxDeposit;
      // }
      // let lFlatMaxValue = this.parseFloat(
      //   this.outputsPurchasePrice.purchasePrice - 30000
      // );

      // this.staticConfig.maxDeposit = lFlatMaxValue > 0 ? lFlatMaxValue : 0;

      // if (this.inputs.depositPurchasePrice >= this.staticConfig.maxDeposit) {
      //   this.inputs.depositPurchasePrice =
      //     this.staticConfig.maxDeposit == 1 ? 0 : this.staticConfig.maxDeposit;
      // }
    } else {
      this.staticConfig.maxDeposit = 1;
      this.inputs.depositMonthlyInstalment = 0;
      this.resetOutput();
    }
  }

  /**
   * Function to handle inputs from the other inputs to ensure limits are kept
   * @param event
   * @param inputName The name of the input
   * @param calculationType Whether it is a monthly instalment or purchase price calculation
   */
  public basicInput(
    event: any,
    inputName: string,
    calculationType: 'MONTHLY' | 'PURCHASEPRICE'
  ) {
    let value: number = parseFloat(event.target.value) || 0;

    let violatesLimit = false;

    switch (inputName) {
      case 'purchasePrice':
        if (value > this.staticConfig.maxFinancingAmount) {
          violatesLimit = true;
        }

        if (
          this.inputs.monthlyInstalment >=
          this.staticConfig.minMonthlyInstalment &&
          this.inputs.monthlyInstalment <=
          this.staticConfig.maxMonthlyInstalment
        ) {
          this.staticConfig.maxDeposit = this.parseTwoDecimal(
            this.outputsPurchasePrice.purchasePrice -
            this.staticConfig.minFinancingAmount
          );

          if (
            this.inputs.depositMonthlyInstalment > this.staticConfig.maxDeposit
          ) {
            this.inputs.depositMonthlyInstalment =
              this.staticConfig.maxDeposit == 1
                ? 0
                : this.staticConfig.maxDeposit;
          }
        } else {
          this.staticConfig.maxDeposit = 1;
          this.resetOutput();
        }

        break;

      case 'deposit':
        switch (calculationType) {
          case 'MONTHLY':
            if (value > this.staticConfig.maxDeposit) {
              violatesLimit = true;
            }
            this.inputs.depositMonthlyInstalment = value || 0;
            break;

          case 'PURCHASEPRICE':
            this.inputs.depositPurchasePrice = value || 0;
            break;
        }

        break;

      case 'balloonPaymentPercentage':
        if (value > this.staticConfig.maxBalloonRate) {
          violatesLimit = true;
        }

        break;

      case 'balloonPaymentAmount':
        if (value > this.staticConfig.maxBalloonAmount) {
          violatesLimit = true;
        }

        this.inputs.balloonPaymentAmount = value;

        break;

      case 'interestRate':
        if (value > this.staticConfig.maxInterestRate) {
          violatesLimit = true;
        }

        break;

      case 'monthlyInstalment':
        if (value > this.staticConfig.maxMonthlyInstalment) {
          violatesLimit = true;
        }

        this.inputs.monthlyInstalment = value;

        if (
          this.inputs.monthlyInstalment >=
          this.staticConfig.minMonthlyInstalment &&
          this.inputs.monthlyInstalment <=
          this.staticConfig.maxMonthlyInstalment
        ) {
          /* REMOVED FOR NOW */
          // let tempMax = this.parseFloat(
          //   this.outputsPurchasePrice.purchasePrice - 30000
          // );
          // this.staticConfig.maxDeposit = tempMax >= 0 ? tempMax : 0;
          // if (this.inputs.depositPurchasePrice > this.staticConfig.maxDeposit) {
          //   if (this.staticConfig.maxDeposit == 0) {
          //     this.staticConfig.maxDeposit = 1;
          //   }
          //   this.inputs.depositPurchasePrice =
          //     this.staticConfig.maxDeposit == 1
          //       ? 0
          //       : this.staticConfig.maxDeposit;
          // }
          // this.staticConfig.maxDeposit = this.parseFloat(
          //   this.outputsPurchasePrice.purchasePrice - 30000
          // );
          // if (this.inputs.depositPurchasePrice > this.staticConfig.maxDeposit) {
          //   this.inputs.depositPurchasePrice =
          //     this.staticConfig.maxDeposit == 1
          //       ? 0
          //       : this.staticConfig.maxDeposit;
          // }
        } else {
          this.staticConfig.maxDeposit = 1;
          this.resetOutput();
        }

        break;
    }

    if (!violatesLimit) {
      switch (calculationType) {
        case 'MONTHLY':
          this.calculateMonthlyInstalment();
          break;

        case 'PURCHASEPRICE':
          this.calculatePurchasePrice();
          break;
      }
    }
  }

  /**
   * Function to handle the blur of basic inputs
   * @param event
   * @param inputName The name of the input
   * @param calculationType Whether it is a monthly instalment or purchase price calculation
   */
  public basicInputBlur(
    event: any,
    inputName: string,
    calculationType: 'MONTHLY' | 'PURCHASEPRICE'
  ): void {
    let value: number = parseFloat(event.target.value) || 0;

    switch (inputName) {
      case 'purchasePrice':
        if (value > this.staticConfig.maxFinancingAmount) {
          this.inputs.purchasePrice = this.staticConfig.maxFinancingAmount;
        } else if (value < 0) {
          this.inputs.purchasePrice = 0;
        } else {
          this.inputs.purchasePrice = value;
        }

        this.inputs.purchasePriceDisplay = this.formatText(
          this.inputs.purchasePrice || 0
        );

        break;

      case 'deposit':
        switch (calculationType) {
          case 'MONTHLY':
            if (value > this.staticConfig.maxDeposit) {
              this.inputs.depositMonthlyInstalment =
                this.staticConfig.maxDeposit;
            } else if (value < 0) {
              this.inputs.depositMonthlyInstalment = 0;
            } else {
              this.inputs.depositMonthlyInstalment = value;
            }

            this.inputs.depositMonthlyInstalmentDisplay = this.formatText(
              this.inputs.depositMonthlyInstalment || 0
            );

            break;
          case 'PURCHASEPRICE':
            this.inputs.depositPurchasePrice = value;

            if (this.inputs.depositPurchasePrice < 0) {
              this.inputs.depositPurchasePrice = 0;
            }

            this.inputs.depositPurchasePriceDisplay = this.formatText(
              this.inputs.depositPurchasePrice || 0
            );
            break;
        }

        break;

      case 'balloonPaymentPercentage':
        if (value > this.staticConfig.maxBalloonRate) {
          this.inputs.balloonPaymentPercentage =
            this.staticConfig.maxBalloonRate;
        }

        if (value < this.staticConfig.minBalloonRate) {
          this.inputs.balloonPaymentPercentage =
            this.staticConfig.minBalloonRate;
        }

        break;

      case 'balloonPaymentAmount':
        if (value > this.staticConfig.maxBalloonAmount) {
          this.inputs.balloonPaymentAmount = this.staticConfig.maxBalloonAmount;
        } else if (value < 0) {
          this.inputs.balloonPaymentAmount = 0;
        } else {
          this.inputs.balloonPaymentAmount = value;
        }

        this.inputs.balloonPaymentAmountDisplay = this.formatText(
          this.inputs.balloonPaymentAmount || 0
        );

        break;

      case 'interestRate':
        if (value > this.staticConfig.maxInterestRate) {
          this.inputs.interestRate = this.staticConfig.maxInterestRate;
        }

        if (value < this.staticConfig.minInterestRate) {
          this.inputs.interestRate = this.staticConfig.minInterestRate;
        }

        break;

      case 'monthlyInstalment':
        if (value > this.staticConfig.maxMonthlyInstalment) {
          this.inputs.monthlyInstalment =
            this.staticConfig.maxMonthlyInstalment;
        } else if (value < 0) {
          this.inputs.monthlyInstalment = 0;
        } else {
          this.inputs.monthlyInstalment = value;
        }

        this.inputs.monthlyInstalmentDisplay = this.formatText(
          this.inputs.monthlyInstalment || 0
        );

        break;
    }

    switch (calculationType) {
      case 'MONTHLY':
        this.calculateMonthlyInstalment();
        break;

      case 'PURCHASEPRICE':
        this.calculatePurchasePrice();
        break;
    }
  }

  /**
   * Function to update the display value after the slider adjusts
   * @param inputName The input's identifier
   */
  public updateSliderDisplay(inputName: string) {
    switch (inputName) {
      case 'depositMonthly':
        this.inputs.depositMonthlyInstalmentDisplay = this.formatText(
          this.inputs.depositMonthlyInstalment
        );
        break;

      case 'balloonAmount':
        this.inputs.balloonPaymentAmountDisplay = this.formatText(
          this.inputs.balloonPaymentAmount
        );
        break;

      default:
        break;
    }
  }

  /**
   * Function to format the text using a thousands separator
   * @param text The text to format
   * @returns The formatted text
   */
  public formatText(text: any): string {
    return parseFloat(text.toString()).toLocaleString();
  }

  /**
   * Function to remove accounting formatting
   * @param event The event that triggered this function
   * @param inputName The input's identifier
   * @param calculationType The type of calculation
   */
  public unformatText(
    event: any,
    inputName: string,
    calculationType: 'MONTHLY' | 'PURCHASEPRICE'
  ) {
    let value: number = parseFloat(event.target.value) || 0;

    switch (inputName) {
      case 'purchasePrice':
        this.inputs.purchasePriceDisplay = this.localeToNumber(
          this.inputs.purchasePriceDisplay
        ).toString();

        break;

      case 'deposit':
        switch (calculationType) {
          case 'MONTHLY':
            if (value > this.staticConfig.maxDeposit) {
              this.inputs.depositMonthlyInstalment =
                this.staticConfig.maxDeposit;
            }
            this.inputs.depositMonthlyInstalmentDisplay = this.localeToNumber(
              this.inputs.depositMonthlyInstalmentDisplay
            ).toString();

            break;
          case 'PURCHASEPRICE':
            this.inputs.depositPurchasePriceDisplay = this.localeToNumber(
              this.inputs.depositPurchasePriceDisplay
            ).toString();

            break;
        }

        break;

      case 'monthlyInstalment':
        this.inputs.monthlyInstalmentDisplay = this.localeToNumber(
          this.inputs.monthlyInstalmentDisplay
        ).toString();
        break;

      case 'balloonAmount':
        this.inputs.balloonPaymentAmountDisplay = this.localeToNumber(
          this.inputs.balloonPaymentAmountDisplay
        ).toString();
        break;
    }
  }

  /**
   * Function to convert a locale formatted string to a number
   * @param localeString The locale formatted string
   * @returns A number from the locale string
   */
  public localeToNumber(localeString: string): number {
    if (localeString) {
      return parseFloat(localeString.replace(/[^\d.-]/g, ''));
    } else {
      return 0;
    }
  }

  /**
   * Function to reset the outputs of the calculator
   */
  private resetOutput(): void {
    this.outputsMonthlyInstalment = {
      monthlyInstalment: 0,
      financeTotal: 0,
      interestTotal: 0,
      balloonTotal: 0,
      totalCost: 0,
      inverseInterestRatio: 100,
      purchasePrice: 0,
    };

    this.outputsPurchasePrice = {
      monthlyInstalment: 0,
      financeTotal: 0,
      interestTotal: 0,
      balloonTotal: 0,
      totalCost: 0,
      inverseInterestRatio: 100,
      purchasePrice: 0,
    };
  }

  /**
   * Function to reset the inputs of the calculator
   */
  private resetInput(): void {
    let config = environment.configs[this.guid];

    this.inputs = {
      purchasePrice: 0,
      purchasePriceDisplay: '0',
      depositMonthlyInstalment: 0,
      depositMonthlyInstalmentDisplay: '0',
      depositPurchasePrice: 0,
      depositPurchasePriceDisplay: '0',
      balloonPaymentPercentage: config.defaultBalloonPercentage,
      balloonPaymentAmount: 0,
      balloonPaymentAmountDisplay: '0',
      interestRate: config.defaultInterestRate,
      paymentTerm: config.defaultPaymentTerm,
      monthlyInstalment: 0,
      monthlyInstalmentDisplay: '0',
      emailAddress: '',
    };

    this.staticConfig.maxDeposit = 1;
  }

  /**
   * Function to reset the calculator to the default state
   */
  public resetToDefault(): void {
    this.resetInput();
    this.resetOutput();
  }

  /**
   * Function to calculate the maximum purchase price given the inputs
   */
  public calculatePurchasePrice(): void {
    /* Ensure the form is valid before calculating */
    if (!this.isPurchasePriceFormValid()) {
      this.resetOutput();
      return;
    }

    let interestRatePercPM = this.inputs.interestRate / 100;
    interestRatePercPM /= 12;

    let cumInterest = 1;

    for (
      let repayCount = 0;
      repayCount < this.inputs.paymentTerm;
      repayCount++
    ) {
      cumInterest *= 1 + interestRatePercPM;
    }

    let financeTotal =
      (this.inputs.balloonPaymentAmount * interestRatePercPM +
        (cumInterest - 1) * this.inputs.monthlyInstalment) /
      (cumInterest * interestRatePercPM);

    let monthlyValue =
      ((financeTotal - this.inputs.balloonPaymentAmount / cumInterest) *
        cumInterest *
        interestRatePercPM) /
      (cumInterest - 1);

    let totalCostOfCredit =
      this.inputs.monthlyInstalment * this.inputs.paymentTerm +
      this.inputs.balloonPaymentAmount;

    let balloonAmount =
      (financeTotal * (this.inputs.balloonPaymentAmount / financeTotal)) / 100;

    /* If the balloon payment is outside of the limits, reset it to not influence the numbers */
    if (
      this.staticConfig.balloonPaymentLimits != null &&
      (this.inputs.paymentTerm >
        this.staticConfig.balloonPaymentLimits.maxTerm ||
        this.inputs.paymentTerm <
          this.staticConfig.balloonPaymentLimits.minTerm)
    ) {
      balloonAmount = 0;
    }

    let interestTotal = totalCostOfCredit - financeTotal;

    this.outputsPurchasePrice = {
      monthlyInstalment: this.parseTwoDecimal(monthlyValue),
      financeTotal: this.parseTwoDecimal(financeTotal),
      interestTotal: this.parseTwoDecimal(interestTotal),
      balloonTotal: this.parseTwoDecimal(this.inputs.balloonPaymentAmount),
      totalCost: this.parseTwoDecimal(totalCostOfCredit),
      inverseInterestRatio: this.parseTwoDecimal(
        ((totalCostOfCredit - interestTotal) / totalCostOfCredit) * 100
      ),
      purchasePrice: this.parseTwoDecimal(
        financeTotal + this.inputs.depositPurchasePrice
      ),
    };

    this.staticConfig.maxBalloonAmount = this.parseTwoDecimal(
      (this.staticConfig.maxBalloonRate / 100) *
      (this.outputsPurchasePrice.financeTotal -
        this.outputsPurchasePrice.interestTotal)
    );
  }

  /**
   * Function to validate the form
   * @returns Whether or not the form is valid
   */
  public isPurchasePriceFormValid(): boolean {
    if (
      this.inputs.monthlyInstalment < this.staticConfig.minMonthlyInstalment ||
      this.inputs.monthlyInstalment > this.staticConfig.maxMonthlyInstalment
    ) {
      return false;
    }

    if (this.inputs.depositPurchasePrice < this.staticConfig.minDeposit) {
      return false;
    }

    if (this.inputs.balloonPaymentAmount < this.staticConfig.minBalloonAmount) {
      return false;
    }

    if (
      this.inputs.interestRate < this.staticConfig.minInterestRate ||
      this.inputs.interestRate > this.staticConfig.maxInterestRate
    ) {
      return false;
    }

    return true;
  }

  /**
   * Function to validate the form
   * @returns Whether or not the form is valid
   */
  public isMonthlyInstalmentFormValid(): boolean {
    if (this.inputs.purchasePrice < this.staticConfig.minFinancingAmount) {
      return false;
    }

    if (
      this.inputs.depositMonthlyInstalment < this.staticConfig.minDeposit ||
      this.inputs.depositMonthlyInstalment > this.staticConfig.maxDeposit
    ) {
      return false;
    }

    if (
      this.inputs.balloonPaymentPercentage < this.staticConfig.minBalloonRate ||
      this.inputs.balloonPaymentPercentage > this.staticConfig.maxBalloonRate
    ) {
      return false;
    }

    if (
      this.inputs.interestRate < this.staticConfig.minInterestRate ||
      this.inputs.interestRate > this.staticConfig.maxInterestRate
    ) {
      return false;
    }

    return true;
  }

  /**
   * Function to parse a float number to a 2 decimal value
   * @param n The number to parse
   * @returns The number delimited to 2 decimals
   */
  private parseTwoDecimal(n: number) {
    if (n !== n) {
      return 0.0;
    }

    return Number.parseFloat(n.toFixed(2));
  }

  /**
   * Function to send an email using the input email address
   * @param calculationType The type of calculation
   */
  public async sendEmail(calculationType: 'MONTHLY' | 'PURCHASEPRICE'): Promise<void> {
    this.isSendInProgress = true;
    let lMessage = '';
    let lSnackBarConfig: MatSnackBarConfig = {
      panelClass: '',
      duration: 3000,
    };
    let request: EmailerRqDto = {
      Recipient: this.inputs.emailAddress,
      ClientGuid: this.guid,
      MessageBody: '',
    };

    try {
      request.MessageBody = this.formatEmailBody(calculationType);

      let response = await this.emailService.sendMail(request);

      if (response.isSuccessful) {
        lMessage = 'Email sent';
        lSnackBarConfig.panelClass = 'snack-bar-success';
      } else {
        lMessage = 'Failed to send email';
        lSnackBarConfig.panelClass = 'snack-bar-failure';
      }

      this.isSendInProgress = false;

      this.snack.open(lMessage, '', lSnackBarConfig);

      this.$gaService.event(this.configName + '_Email', 'Communication');

    } catch (error) {
      lMessage = 'Email could not be sent at this time';
      lSnackBarConfig.panelClass = 'snack-bar-failure';

      this.snack.open(lMessage, '', lSnackBarConfig);

      this.isSendInProgress = false;
    }
  }

  /**
   * Function to format the body of the email
   * @param calculationType
   * @returns The body of the email
   */
  private formatEmailBody(
    calculationType: 'MONTHLY' | 'PURCHASEPRICE'
  ): string {
    let lBody = '';
    let config = environment.configs[this.guid];

    try {
      switch (calculationType) {
        case 'MONTHLY':
          // if (this.inputs.balloonPaymentPercentage) {
          //   lBalloonSection = ` and a balloon payment of <b>${this.inputs.balloonPaymentPercentage}%</b>`;
          // }

          lBody = config.smtp!.MonthlyInstalmentBody as string;

        lBody = lBody.replace(
          /{PurchasePrice}/g,
          `${this.staticConfig.currency}${this.inputs.purchasePriceDisplay}`
        );
        lBody = lBody.replace(
          /{Deposit}/g,
          `${this.staticConfig.currency}${this.inputs.depositMonthlyInstalmentDisplay}`
        );
        lBody = lBody.replace(
          /{InterestRate}/g,
          `${this.inputs.interestRate.toString()}%`
        );
        lBody = lBody.replace(
          /{MonthlyInstalment}/g,
          `${this.staticConfig.currency}${this.formatText(
            this.outputsMonthlyInstalment.monthlyInstalment
          )}`
        );
        lBody = lBody.replace(
          /{BalloonPaymentRate}/g,
          `${this.staticConfig.disableBalloonPayment ? '0' : this.inputs.balloonPaymentAmount}%`
        );
        lBody = lBody.replace(
          /{FinanceTotal}/g,
          `${this.staticConfig.currency}${this.formatText(
            this.outputsMonthlyInstalment.financeTotal
          )}`
        );
        lBody = lBody.replace(
          /{InterestTotal}/g,
          `${this.staticConfig.currency}${this.formatText(
            this.outputsMonthlyInstalment.interestTotal
          )}`
        );
        lBody = lBody.replace(
          /{TotalCostOfCredit}/g,
          `${this.staticConfig.currency}${this.formatText(
            this.outputsMonthlyInstalment.totalCost
          )}`
        );

          lBody = lBody.replace(
            /{Deposit}/g,
            `${this.staticConfig.currency}${this.inputs.depositMonthlyInstalmentDisplay}`
          );

          lBody = lBody.replace(
            /{InterestRate}/g,
            `${this.inputs.interestRate.toString()}%`
          );

          lBody = lBody.replace(
            /{MonthlyInstalment}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsMonthlyInstalment.monthlyInstalment
            )}`
          );

          lBody = lBody.replace(
            /{BalloonPaymentRate}/g,
            `${this.inputs.balloonPaymentPercentage}%`
          );

        lBody = lBody.replace(
          /{MonthlyInstalment}/g,
          `${this.staticConfig.currency}${this.inputs.monthlyInstalmentDisplay}`
        );
        lBody = lBody.replace(
          /{PaymentTerm}/g,
          `${this.inputs.paymentTerm.toString()}`
        );
        lBody = lBody.replace(
          /{Deposit}/g,
          `${this.staticConfig.currency}${this.inputs.depositPurchasePriceDisplay}`
        );
        lBody = lBody.replace(
          /{InterestRate}/g,
          `${this.inputs.interestRate.toString()}%`
        );
        lBody = lBody.replace(
          /{BalloonPayment}/g,
          `${this.staticConfig.currency}${this.staticConfig.disableBalloonPayment ? '0' : this.inputs.balloonPaymentAmountDisplay}`
        );
        lBody = lBody.replace(
          /{PurchasePrice}/g,
          `${this.staticConfig.currency}${this.formatText(
            this.outputsPurchasePrice.purchasePrice
          )}`
        );

          lBody = lBody.replace(
            /{InterestTotal}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsMonthlyInstalment.interestTotal
            )}`
          );

          lBody = lBody.replace(
            /{TotalCostOfCredit}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsMonthlyInstalment.totalCost
            )}`
          );

          // lBody = `<h1>Finance Calculator result</h1><br>For a vehicle costing a total of <b>${
          //   this.staticConfig.currency
          // } ${
          //   this.inputs.purchasePriceDisplay
          // }</b>, and placing a deposit of <b>${this.staticConfig.currency} ${
          //   this.inputs.depositMonthlyInstalmentDisplay
          // }</b> with an interest rate of <b>${
          //   this.inputs.interestRate
          // }%</b>${lBalloonSection}, the following will be applicable:<br><br>Monthly instalments: ${
          //   this.staticConfig.currency
          // } ${this.formatText(
          //   this.outputsMonthlyInstalment.monthlyInstalment
          // )}<br>Financing amount: ${this.staticConfig.currency} ${this.formatText(
          //   this.outputsMonthlyInstalment.financeTotal
          // )}<br>Interest amount: ${this.staticConfig.currency} ${this.formatText(
          //   this.outputsMonthlyInstalment.interestTotal
          // )}<br>Total cost of credit: ${
          //   this.staticConfig.currency
          // } ${this.formatText(this.outputsMonthlyInstalment.totalCost)}<br>`;

          break;

        case 'PURCHASEPRICE':
          // if (this.inputs.balloonPaymentAmount) {
          //   lBalloonSection = ` and a balloon payment of <b>${this.staticConfig.currency} ${this.inputs.balloonPaymentAmountDisplay}</b>`;
          // }

          lBody = config.smtp!.PurchasePriceBody as string;

          lBody = lBody.replace(
            /{MonthlyInstalment}/g,
            `${this.staticConfig.currency}${this.inputs.monthlyInstalmentDisplay}`
          );

          lBody = lBody.replace(
            /{PaymentTerm}/g,
            `${this.inputs.paymentTerm.toString()}`
          );

          lBody = lBody.replace(
            /{Deposit}/g,
            `${this.staticConfig.currency}${this.inputs.depositPurchasePriceDisplay}`
          );

          lBody = lBody.replace(
            /{InterestRate}/g,
            `${this.inputs.interestRate.toString()}%`
          );

          lBody = lBody.replace(
            /{BalloonPayment}/g,
            `${this.staticConfig.currency}${this.inputs.balloonPaymentAmountDisplay}`
          );

          lBody = lBody.replace(
            /{PurchasePrice}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsPurchasePrice.purchasePrice
            )}`
          );

          lBody = lBody.replace(
            /{FinanceTotal}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsPurchasePrice.financeTotal
            )}`
          );

          lBody = lBody.replace(
            /{InterestTotal}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsPurchasePrice.interestTotal
            )}`
          );

          lBody = lBody.replace(
            /{TotalCostOfCredit}/g,
            `${this.staticConfig.currency}${this.formatText(
              this.outputsPurchasePrice.totalCost
            )}`
          );

          // lBody = `<h1>Finance Calculator result</h1><br>By spending <b>${
          //   this.staticConfig.currency
          // } ${this.inputs.monthlyInstalmentDisplay}</b> per month for ${
          //   this.inputs.paymentTerm
          // } months, and placing a deposit of <b>${this.staticConfig.currency} ${
          //   this.inputs.depositPurchasePriceDisplay
          // }</b> with an interest rate of <b>${
          //   this.inputs.interestRate
          // }%</b>${lBalloonSection}, you can afford the following:<br><br>Purchase price: ${
          //   this.staticConfig.currency
          // } ${this.formatText(
          //   this.outputsPurchasePrice.purchasePrice
          // )}<br>Financing amount: ${this.staticConfig.currency} ${this.formatText(
          //   this.outputsPurchasePrice.financeTotal
          // )}<br>Interest amount: ${this.staticConfig.currency} ${this.formatText(
          //   this.outputsPurchasePrice.interestTotal
          // )}<br>Total cost of credit: ${
          //   this.staticConfig.currency
          // } ${this.formatText(this.outputsPurchasePrice.totalCost)}<br>`;
          break;
      }
    } catch (error) {
      console.log(error);
    }

    return lBody;
  }

  public tabChanged(event: MatTabChangeEvent): void {
    this.$gaService.event(
      `${this.configName}_${event.tab.textLabel}`,
      'Navigation'
    );
  }

  public checkBalloonPaymentLimits(): void {
    if (this.staticConfig.balloonPaymentLimits != null) {
      if (
        this.inputs.paymentTerm >
          this.staticConfig.balloonPaymentLimits.maxTerm ||
        this.inputs.paymentTerm < this.staticConfig.balloonPaymentLimits.minTerm
      ) {
        this.staticConfig.disableBalloonPayment = true;

        return;
      }
    }

    this.staticConfig.disableBalloonPayment = false;
  }
}
